<template>
  <el-dialog
    :model-value="dialogVisible"
    :title="dialogTitle"
    width="500px"
    @opened="handleOpen"
    @close="handleClose"
    draggable
  >
    <el-form :model="form" label-width="85px" ref="formRef" :rules="rules">
      <el-form-item label="设备名称" prop="Name">
        <el-input v-model="form.Name" />
      </el-form-item>
      <el-form-item label="设备类型" prop="DeviceTypeID">
        <el-select
          v-model="form.DeviceTypeID"
          placeholder="Select"
          style="width: 100%"
          @change="handleChangeDeviceType"
        >
          <el-option
            v-for="item in typeInfo"
            :key="item.ID"
            :label="item.Name"
            :value="item.ID"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="默认场景1">
        <el-select
          v-model="form.DefaultScene"
          placeholder="请选择PC默认场景"
          filterable
          style="width: 100%"
        >
          <el-option
            v-for="item in ScenesList"
            :key="item.ID"
            :label="item.Name"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="默认场景2">
        <el-select
          v-model="form.DefaultScene2"
          placeholder="请选择微信小程序默认场景"
          filterable
          style="width: 100%"
        >
          <el-option
            v-for="item in ScenesList"
            :key="item.ID"
            :label="item.Name"
            :value="item.ID"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="客户类型" prop="CustomeType">
        <el-select
          v-model="form.CustomeType"
          placeholder="Select"
          @change="HandleChangeCustomType"
          style="width: 100%"
        >
          <el-option
            v-for="item in cutomeTypeInfo"
            :key="item.ID"
            :label="item.Name"
            :value="item.ID"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="归属客户" prop="CustomeID">
        <el-select
          v-model="form.CustomeID"
          placeholder="Select"
          style="width: 100%"
        >
          <el-option
            v-for="item in cutomeInfo"
            :key="item.ID"
            :label="item.Name"
            :value="item.ID"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="设备编码" prop="Code">
        <el-input v-model="form.Code" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="设备型号">
        <el-input v-model="form.DeviceModel" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="安装位置">
        <el-input v-model="form.Location" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="地理坐标">
        <el-input v-model="form.XY" autocomplete="off">
          <template #append>
            <el-button :icon="Search" @click="showMap"></el-button>
          </template>
        </el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
    <MapDialog
      v-model="MapDialogVisible"
      v-if="MapDialogVisible"
      @SetXYData="SetXYData"
    ></MapDialog>
  </el-dialog>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { AddDevice, EditDevice, GetCustomeFillList } from '@/api/Device'
import { GetDeviceTypeFillList } from '@/api/DeviceType'
import { ElMessage } from 'element-plus'
import { isNull } from '@/utitls/validate'
import MapDialog from './mapDialog.vue'
import { Search, Refresh } from '@element-plus/icons-vue'
import { GetScenesFillList } from '@/api/Scenes'
const props = defineProps({
  dialogTitle: {
    type: String,
    default: '',
    required: true
  },
  dialogRowValue: {
    type: Object,
    default: () => {}
  }
})
const formRef = ref(null)
var form = ref({ CustomeType: 0 })
const MapDialogVisible = ref(false)
const typeInfo = ref([])
const cutomeTypeInfo = ref([
  {
    ID: 0,
    Name: '企业客户'
  },
  {
    ID: 1,
    Name: '个人客户'
  }
])
const cutomeInfo = ref([])
const dialogVisible = ref(false)
const rules = reactive({
  Name: [{ required: true, message: '类型名称不能为空', trigger: 'blur' }],
  DeviceTypeID: [
    { required: true, message: '设备类型不能为空', trigger: 'blur' }
  ],
  CustomeType: [
    { required: true, message: '客户类型不能为空', trigger: 'blur' }
  ],
  CustomeID: [{ required: true, message: '归属客户不能为空', trigger: 'blur' }],
  Code: [{ required: true, message: '类型编码不能为空', trigger: 'blur' }]
})

const emits = defineEmits(['update:modelValue', 'initList'])
const handleOpen = () => {
  GetDeviceTypeFillList().then((res) => {
    if (res) {
      res.Data.forEach((item) => {
        typeInfo.value.push({
          ID: item.ID,
          Name: item.Name
        })
      })
    }
  })
  if (props.dialogTitle === '修改设备信息') {
    form.value = props.dialogRowValue
    getScences()
  }
  getCustomFillInfo()
}
const handleClose = () => {
  emits('update:modelValue', false)
}
const handleConfirm = () => {
  formRef.value.validate(async (valid, fields) => {
    if (valid) {
      if (props.dialogTitle == '添加设备') {
        AddDevice(form.value)
          .then((res) => {
            ElMessage({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
            emits('initList')
            handleClose()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: `操作失败:${err}`,
              type: 'error'
            })
          })
      } else
        EditDevice(form.value)
          .then((res) => {
            ElMessage({
              showClose: true,
              message: '操作成功',
              type: 'success'
            })
            emits('initList')
            handleClose()
          })
          .catch((err) => {
            ElMessage({
              showClose: true,
              message: `操作失败:${err}`,
              type: 'error'
            })
          })
    } else {
      console.log('error submit!', fields)
    }
  })
}
const HandleChangeCustomType = (e) => {
  getCustomFillInfo()
  form.value.CustomeID = ''
}
const getCustomFillInfo = () => {
  cutomeInfo.value.length = 0
  GetCustomeFillList({ customeType: form.value.CustomeType }).then((res) => {
    if (res) {
      res.Data.forEach((item) => {
        cutomeInfo.value.push({
          ID: item.ID,
          Name: item.Name
        })
      })
    }
  })
}
const showMap = () => {
  MapDialogVisible.value = true
}
const SetXYData = (xy) => {
  form.value.XY = xy
}
const ScenesList = ref([])
const handleChangeDeviceType = (e) => {
  form.value.DefaultScene = ''
  getScences()
}
const getScences = () => {
  ScenesList.value.length = 0
  GetScenesFillList({ key: '', deviceTypeID: form.value.DeviceTypeID })
    .then((res) => {
      if (res) {
        res.Data.forEach((item) => {
          ScenesList.value.push(item)
        })
      }
    })
    .catch((err) => {
      console.log(err)
    })
}
</script>
