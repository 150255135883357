<template>
  <div class="content">
    <TitleInfo title1="设备管理" title2="设备档案"></TitleInfo>
    <div class="main">
      <div class="title flexCB">
        <div class="key align-center">
          <div>设备类型：</div>
          <el-select v-model="queryForm.deviceTypeID" placeholder="Select">
            <el-option
              v-for="item in typeInfo"
              :key="item.ID"
              :label="item.Name"
              :value="item.ID"
            />
          </el-select>
          <div style="margin-left: 10px">客户类型：</div>
          <el-select v-model="queryForm.customeType" placeholder="Select">
            <el-option
              v-for="item in cutomeTypeInfo"
              :key="item.ID"
              :label="item.Name"
              :value="item.ID"
            />
          </el-select>
          <div style="margin-left: 10px">设备名称：</div>
          <el-input
            v-model="queryForm.name"
            placeholder="请输入设备名称"
            class="keyInput"
          />
          <div>设备编码：</div>
          <el-input
            v-model="queryForm.code"
            placeholder="请输入设备编码"
            class="keyInput"
          />
          <el-button
            :icon="Search"
            color="#11488B"
            :round="false"
            @click="initGetList"
            >搜索</el-button
          >
          <el-button
            :icon="Refresh"
            color="#67C23A"
            :round="false"
            @click="resetInfo"
            >重置</el-button
          >
          <el-button
            v-if="user.CanAdd"
            :icon="Plus"
            type="warning"
            :round="false"
            @click="handleDialogValue()"
            >新增</el-button
          >
        </div>
      </div>
      <div class="tableInfo">
        <el-table
          :data="tableData"
          border
          class="tableInfo"
          style="width: 100%; height: 100%"
        >
          <el-table-column type="index" width="50"> </el-table-column>
          <el-table-column prop="Name" label="设备名称"></el-table-column>
          <el-table-column prop="Code" label="设备编码"></el-table-column>
          <el-table-column
            prop="DeviceTypeName"
            label="设备类型"
          ></el-table-column>
          <el-table-column
            prop="DefaultSceneLabel"
            label="默认场景"
          ></el-table-column>
          <el-table-column
            prop="DefaultSceneLabel2"
            label="默认场景2"
          ></el-table-column>
          <el-table-column
            prop="CustomeTypeName"
            label="客户类型"
          ></el-table-column>
          <el-table-column
            prop="CustomeName"
            label="客户名称"
          ></el-table-column>
          <el-table-column
            prop="DeviceModel"
            label="设备型号"
          ></el-table-column>
          <el-table-column prop="Location" label="安装位置"></el-table-column>
          <el-table-column
            prop="action"
            label="操作"
            width="200"
            v-if="user.CanDelete || user.CanEdit"
          >
            <template #default="{ row }">
              <el-button
                v-if="user.CanEdit"
                size="small"
                type="warning"
                :icon="Edit"
                @click="handleDialogValue(row)"
                >编辑</el-button
              >
              <el-button
                v-if="user.CanDelete"
                size="small"
                type="danger"
                :icon="Delete"
                @click="del(row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="bottom align-center">
        <el-pagination
          v-model:current-page="queryForm.pageIndex"
          v-model:page-size="queryForm.pageSize"
          :page-sizes="[10, 20, 50, 100]"
          :background="true"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          popper-class="dropdownPopper"
        />
      </div>
    </div>
    <Dialog
      v-model="dialogVisible"
      :dialogTitle="dialogTitle"
      :dialogRowValue="rowInfo"
      v-if="dialogVisible"
      @initList="initGetList"
    ></Dialog>
  </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'
import { Search, Refresh, Plus, Edit, Delete } from '@element-plus/icons-vue'
import { isNull } from '@/utitls/validate'
import { ElMessage, ElMessageBox } from 'element-plus'
import TitleInfo from '@/components/TitleInfo'
import { GetDeviceTypeFillList } from '@/api/DeviceType'
import { GetDevicePageList, DeleteDevice } from '@/api/Device'
import Dialog from './components/dialog.vue'
import { useStore } from 'vuex'
const queryForm = ref({
  deviceTypeID: '',
  customeType: -1,
  name: '',
  code: '',
  pageIndex: 1,
  pageSize: 10
})
const typeInfo = ref([
  {
    ID: '',
    Name: '全部'
  }
])
const cutomeTypeInfo = ref([
  {
    ID: -1,
    Name: '全部'
  },
  {
    ID: 0,
    Name: '企业客户'
  },
  {
    ID: 1,
    Name: '个人客户'
  }
])
const total = ref(0)
const tableData = reactive([])
const dialogVisible = ref(false)
const dialogTitle = ref('')
var rowInfo = ref({})
const user = ref({})
onMounted(() => {
  user.value = useStore().state.login.user
  GetDeviceTypeFillList()
    .then((res) => {
      if (res) {
        res.Data.forEach((item) => {
          typeInfo.value.push({
            ID: item.ID,
            Name: item.Name
          })
        })
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
    })
  initGetList()
})
const initGetList = async () => {
  GetDevicePageList(queryForm.value)
    .then((res) => {
      if (res) {
        total.value = res.Total
        tableData.length = 0
        res.Data.forEach((element) => {
          tableData.push(element)
        })
      } else {
        total.value = 0
        tableData.length = 0
      }
    })
    .catch((err) => {
      ElMessage({
        showClose: true,
        message: `${err}`,
        type: 'error'
      })
      console.log(err)
      total.value = 0
      tableData.length = 0
    })
}
const handleSizeChange = (pageSize) => {
  queryForm.value.pageIndex = 1
  initGetList()
}
const handleCurrentChange = (pageNum) => {
  initGetList()
}
const handleDialogValue = (row) => {
  if (isNull(row)) {
    dialogTitle.value = '添加设备'
    rowInfo.value = {}
  } else {
    dialogTitle.value = '修改设备信息'
    rowInfo.value = JSON.parse(JSON.stringify(row))
  }
  dialogVisible.value = true
}
const del = (row) => {
  ElMessageBox.confirm('是否确认删除当前设备?', '提醒', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      DeleteDevice({ id: row.ID })
        .then((res) => {
          if (res) {
            ElMessage({
              showClose: true,
              type: 'success',
              message: '删除成功!'
            })
            initGetList()
          }
        })
        .catch((err) => {
          ElMessage({
            showClose: true,
            type: 'error',
            message: err,
            duration: 2000
          })
        })
    })
    .catch(() => {})
}
const resetInfo = () => {
  queryForm.value = {
    deviceTypeID: '',
    customeType: -1,
    name: '',
    code: '',
    pageIndex: 1,
    pageSize: 10
  }
  initGetList()
}
</script>
<style lang="scss" scoped src="@/styles/elementReset.scss"></style>
<style lang="scss" scoped src="./assets/index.scss"></style>
